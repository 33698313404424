import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Footer from '../Footer'
import Main from '../Main'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { IntlProvider, addLocaleData } from 'react-intl'
import 'intl'
import en from 'react-intl/locale-data/en'
import 'intl/locale-data/jsonp/en'
import ka from 'react-intl/locale-data/ka'
import 'intl/locale-data/jsonp/ka'

// add concatenated locale data
addLocaleData([...en, ...ka])

class TemplateWrapper extends Component {
    constructor(props) {
        super(props)
        this.children = this.props.children
        const data = this.props.data
        this.description = data.markdownRemark.frontmatter.description
        this.className = this.props.className
        const location = this.props.location
        this.title = data.markdownRemark.frontmatter.title
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        this.langKey = getCurrentLangKey(langs, defaultLangKey, url)
        this.homeLink = `/${this.langKey}/`
        this.langsMenu = getLangs(
            langs,
            this.langKey,
            getUrlForLang(this.homeLink, url)
        )

        // get the appropriate message file based on langKey
        // at the moment this assumes that langKey will provide us
        // with the appropriate language code
        this.i18nMessages = require(`../../data/messages/${this.langKey}`)
    }

    render() {
        return (
            <IntlProvider locale={this.langKey} messages={this.i18nMessages}>
                <div>
                    <Helmet
                        key="app-head"
                        defaultTitle={this.title}
                        titleTemplate={`%s - ${this.title}`}
                    >
                        <html lang={this.langKey} />
                        <meta name="description" content={this.description} />
                    </Helmet>
                    <Header
                        langKey={this.langKey}
                        langs={this.langsMenu}
                        menu={this.menuTree}
                    />
                    <Main key="app-main" className={this.className}>
                        {this.children}
                    </Main>
                    <Footer langKey={this.langKey} />
                </div>
            </IntlProvider>
        )
    }
}

TemplateWrapper.propTypes = {
    children: PropTypes.object,
    data: PropTypes.object,
    className: PropTypes.string,
    location: PropTypes.object,
}

export default TemplateWrapper
